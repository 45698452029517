<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between">
            <h4>All Customers</h4>
            <div class="card-header-action">
              <div class="dropdown">
                <a
                  href="#"
                  class="dropdown-toggle btn btn-primary"
                  data-toggle="dropdown"
                  >Filter</a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveCustomers('all')"
                    ><i class="fas fa-star"></i> All</a
                  >
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveCustomers('existing')"
                    ><i class="fas fa-briefcase"></i> Existing Customer</a
                  >
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveCustomers('prospect')"
                    ><i class="far fa-user"></i> Prospective Customer</a
                  >
                  <a href="#" class="dropdown-item has-icon" @click.prevent="retrieveCustomers('agent')"
                    ><i class="fas fa-bullhorn"></i> Agents</a
                  >
                </div>
              </div>
            </div>
            <router-link
              to="/admin/customers/create"
              class="btn btn-icon icon-left btn-primary"
              style="margin-left: 15px"
              ><i class="far fa-plus"></i> Add Customer</router-link
            >
          </div>
          <div class="card-body">
            <!-- <div class="row mb-3">
              <div class="col">
                <div class="float-left">
                  <select class="form-control selectric">
                    <option>Action For Selected</option>
                    <option>Move to Draft</option>
                    <option>Move to Pending</option>
                    <option>Delete Pemanently</option>
                  </select>
                </div>
                <div class="float-right">
                  <form>
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="Search">
                      <div class="input-group-append">                                            
                        <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> -->
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Serial number</th>
                    <th>Location</th>
                    <th>Category</th>
                    <th>[Action]</th>
                  </tr>
                </thead>
                <tbody v-if="customers.length > 0">
                  <tr v-for="(customer, index) in customers" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ customer.customer_name }}</td>
                    <td>{{ customer.phone }}</td>
                    <td>{{ customer.serial_number ? customer.serial_number :  '-' }}</td>
                    <td>{{ `${customer.lga}, ${$globalFunc.capitalizeFirstLetter(customer.state.replace("_", " "))}` }}</td>
                    <td>
                      <div class="badge badge-success" v-if="customer.customer_type == 'existing'">
                        Existing
                      </div>
                      <div class="badge badge-warning" v-else-if="customer.customer_type == 'prospect'">
                        Prospect
                      </div>
                      <div class="badge badge-info" v-else>
                        Agent
                      </div>
                    </td>
                    <td>
                      <a
                        class="btn btn-danger btn-action"
                        data-toggle="tooltip"
                        title="Delete"
                        @click.prevent="deleteCustomer(customer.id)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7">No record found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <h6>
                <strong>Total Customers:</strong> {{ customers.length }}
              </h6>
            </div>
            <!-- <div class="row">
              <div class="col">
                <div class="float-right">
                  <nav>
                    <ul class="pagination">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="#">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import GeneralHelpers from "@/functions/actions/GeneralRequest";
import { mapState } from "vuex";
export default {
  name: "Customers",
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.retrieveCustomers(this.filter);
  },
  data() {
    return {
      customers: [],
      filter: 'all'
    };
  },
  methods: {
    async retrieveCustomers(type) {
      const response = await GeneralHelpers.getCustomerByType(type);
      if (response.status) {
        this.customers = response.customers;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async deleteCustomer(customerId) {
      this.$confirm("Are you sure?").then(async () => {
        const response = await GeneralHelpers.deleteCustomer(customerId)
        if (response.status) {
          this.customers = this.customers.filter(customer => customer.id != customerId)
          this.showAlert('Success', 'Customer deleted successfully', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>